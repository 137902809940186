<template>
  <div class="mx-1">
    <b-row v-if="formLoaded">
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="createTask(task_type)"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <table-task
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      @edit="onEdit"
      :onlyActions="['edit']"
      noInnerWidth
    >
    </table-task>
    <div v-if="formLoaded">
      <event-create-modal
        v-model="evt"
        :beFormCreate="beCreateForm['task']"
        :beRulesCreate="beRules['task']"
        @cancel="cancelEventCreation"
        @create="storeEvent"
      ></event-create-modal>
      <event-change-modal
        v-model="evt"
        :beFormUpdate="beEditForm['task']"
        :beRulesUpdate="beRules['task']"
        @cancel="cancelEventChange"
        @update="putEvent({ id: task_id })"
      ></event-change-modal>
    </div>
  </div>
</template>

<script>
import TableTask from "@/components/tables/Tasks";
import FormMixin from "@/mixins/FormMixin";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [
    CalendarEventsAndTasksMixin,
    FormMixin,
    DetailTabAutofetchTableMixin,
  ],
  name: "Todo",
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterBookEntriesTodoDetail",
      repository: "task",
      resource: "tasks",
      tableRef: "bookEntriesTodoDetailTableRef",
      taskable_id: this.resourceId,
      taskable: "book_entry",
      task_type: 1,
      task_id: null,
      formLoaded: false,
      beCreateForm: {}, // define this copy for co-existende of create an edit inside the same component
      beEditForm: {}, // define this copy for co-existende of create an edit inside the same component
      form: {
        task: {
          id: null,
          title: null,
          description: null,
          task_type: null,
          status_task: null,
          users: null,
          owner_id: null,
          taskable: this.resourceId, //nome della relazione
          taskable_id: null, //array di ID di modelli (verosimilmente sempre 1 nelle ns. GUI)
          start: null, // date
          end: null, // date
          startTime: null,
          endTime: null,
          expired_at: null,
        },
      },
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "task"),
          sortable: false,
          sortKey: "title",
        },
        {
          key: "description",
          label: this.getDictionary("description", "task"),
          sortable: false,
          sortKey: "description",
        },
        {
          label: this.getDictionary("task_type", "task"),
          key: "task_type.text",
          sortable: false,
          sortKey: "task_type",
        },
        {
          label: this.getDictionary("is_public", "task"),
          key: "is_public.text",
          sortable: false,
          sortKey: "is_public",
        },
        {
          label: this.getDictionary("users", "task"),
          key: "users",
          sortable: false,
          sortKey: "users",
        },
      ],
    };
  },
  components: {
    TableTask,
  },
  methods: {
    initFilter() {
      let init = {
        byBookEntry: { id: this.resourceId },
        byAttribute: { task_type: 1 },
      };
      return init;
    },
    onEdit(id) {
      this.task_id = id;
      this.handleChangeClick({ id: id });
    },
  },
  props: {
    resourceId: Number,
  },
  beforeMount() {
    this.initDefaultFormValues({ task_type: this.task_type });
    this.fetchCreateForm(this.repository)
      .then(() => {
        this.beCreateForm[this.repository] = this.beEditForm[this.repository] =
          this.convertDataToCalendarEvents([this.beForm[this.repository]])[0];
        this.formLoaded = true;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
