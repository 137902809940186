<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-select
              vid="relazioni"
              name="relazioni"
              label="Relazioni"
              v-model="form.request_input.relazioni"
              :options="beFormReport.relazioni.options"
              @input="onInputRelations"
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :options="getInsurers()"
              @input="onInputInsurer"
              :taggable="true"
              :multiple="true"
            />
            <base-select
              vid="rami"
              name="rami"
              label="Rami"
              v-model="form.request_input.rami"
              :options="getBranches()()"
              :taggable="true"
              :multiple="true"
            />
            <base-select
              vid="prodotti"
              name="prodotti"
              label="Prodotti"
              v-model="form.request_input.prodotti"
              :options="getRisks()"
              :taggable="true"
              :multiple="true"
              :disabled="risksDisabled"
            />
            <base-select
              vid="formato_polclirel"
              name="formato_polclirel"
              label="Formato"
              v-model="form.request_input.formato_polclirel"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_polclirel.options
                )
              "
              :rules="{ required: true }"
            />
            <base-select
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :rules="{ required: true }"
              :options="allowedFormatOptions"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  mixins: [StorageGetterMixin],
  data() {
    return {
      risksDisabled: false,
      selected: null,
      form: {
        format: null,
        request_input: {
          clienti: [],
          relazioni: [],
          prodotti: [],
          formato_polclirel: null,
        },
      },
    };
  },
  components: {
    BaseSelect,
  },
  methods: {
    onInputInsurer(val) {
      if (val.length > 1) {
        this.form.request_input.prodotti = [];
        this.risksDisabled = true;
      } else {
        this.risksDisabled = false;
      }
    },
    onInputRelations(val) {
      console.log("val: ", val);
      if (val) {
        this.form.request_input.relazioni = [val];
      }
    },
    ...mapGetters("auth", {
      getCooperators: "cooperators",
      getInsurers: "insurers",
      getRisks: "risks",
      getBranches: "branches",
    }),
  },
  created() {
    /* */
    console.log("created POLCLIREL");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("cooperators"))
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {
            console.info(
              `background data "${path}cooperators" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
};
</script>
