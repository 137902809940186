var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createReport)}}},[_c('b-card',[_c('b-card-text',[_c('base-radio',{attrs:{"name":_vm.beFormReport.format.label,"vid":"format","label":_vm.beFormReport.format.label,"options":_vm.allowedFormatOptions,"rules":{ required: true }},model:{value:(_vm.form.format),callback:function ($$v) {_vm.$set(_vm.form, "format", $$v)},expression:"form.format"}}),_vm._v(" "),_c('base-select',{attrs:{"vid":"compagnie","name":"compagnie","label":"Compagnie","options":_vm.getInsurers(),"taggable":true,"multiple":true},model:{value:(_vm.form.request_input.compagnie),callback:function ($$v) {_vm.$set(_vm.form.request_input, "compagnie", $$v)},expression:"form.request_input.compagnie"}}),_vm._v(" "),_c('base-select',{attrs:{"vid":"produttori","name":"produttori","label":"Produttori","options":_vm.getSalesmen(),"taggable":true,"multiple":true},model:{value:(_vm.form.request_input.produttori),callback:function ($$v) {_vm.$set(_vm.form.request_input, "produttori", $$v)},expression:"form.request_input.produttori"}}),_vm._v(" "),_c('base-radio',{attrs:{"vid":"raggruppa_produttori","name":"raggruppa_produttori","label":"Attiva raggruppamento produttori","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.request_input.raggruppa_produttori),callback:function ($$v) {_vm.$set(_vm.form.request_input, "raggruppa_produttori", $$v)},expression:"form.request_input.raggruppa_produttori"}}),_vm._v(" "),_c('base-radio',{attrs:{"vid":"solo_totali_produttori","name":"solo_totali_produttori","label":"Attiva stampa solo totali per produttore","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.request_input.solo_totali_produttori),callback:function ($$v) {_vm.$set(_vm.form.request_input, "solo_totali_produttori", $$v)},expression:"form.request_input.solo_totali_produttori"}})],1),_vm._v(" "),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","disabled":invalid,"variant":"outline-lisaweb","size":"sm"}},[_vm._v("\n          Salva\n        ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }