<template>
  <b-modal
    id="selectOption"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="">
      <div slot="modal-title" class="modal-title">
        <!-- <b-icon icon="cash"></b-icon> -->
        <span>{{ title }}</span>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-button @click="cancel()" size="sm" variant="outline-secondary"
          >ANNULLA</b-button
        >
        <b-button
          class="btn-outline-lisaweb"
          :disabled="option == null"
          @click="
            $emit('select', option);
            cancel();
          "
          size="sm"
          variant="outline-success"
          >CONTINUA</b-button
        >
      </b-form-group>
    </template>
    <b-row>
      <div class="col-md-12 mb-3">
        Per procedere è necessario selezionare un'opzione
      </div>
    </b-row>
    <b-row>
      <div class="col-md-12">
        <base-select
          v-model="option"
          name="options"
          :label="title"
          :options="opts"
          @select="onSelectOption"
        />
      </div>
    </b-row>
  </b-modal>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
export default {
  data() {
    return {
      option: null,
    };
  },
  components: {
    BaseSelect,
  },
  props: {
    opts: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "Opzioni",
    },
  },
  computed: {},
  beforeMount() {},
  methods: {
    onSelectOption(selected) {
      // console.debug("onSelectOption: ", selected);
      // this.$emit("select", selected);
      // this.$bvModal.hide("selectOption");
      this.option = selected;
    },
  },
};
</script>
<style lang="scss" scoped></style>
