<template>
  <div class="mt-1 mx-3">
    <bank-statement> </bank-statement>
  </div>
</template>

<script>
import BankStatement from "@/components/accounting/bank-statement/index.vue";
export default {
  props: {
    msg: String,
  },
  components: {
    BankStatement,
  },
  created() {},
};
</script>
