<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${uid}`"
      :label="`${label}`"
      :label-for="`input-${uid}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid) ||
          (Object.keys(rules).includes('required_with') && !valid),
      }"
    >
      <monthpicker
        :class="getValidationStateClass({ dirty, validated, valid, errors })"
        :id="id"
        :name="`input-${uid}`"
        :monthLabels="[
          'Gen',
          'Feb',
          'Mar',
          'Apr',
          'Mag',
          'Giu',
          'Lug',
          'Ago',
          'Set',
          'Ott',
          'Nov',
          'Dic',
        ]"
        dateFormat="MM/YYYY"
        selectedBackgroundColor="#f9991e"
        :clearOption="clearable"
        v-model="inputVal"
        @selected="onSelected"
        :aria-describedby="`input-${id}-live-feedback`"
      />
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: undefined,
    id: undefined,
    vid: String,
    name: String,
    label: String,
    clearable: Boolean,
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      uid: null,
    };
  },
  mounted() {
    this.uid = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(`inputVal GET: ${JSON.stringify(this.value)}`);
        return this.value;
      },
      set(val) {
        // console.debug(`inputVal SET: ${JSON.stringify(val)}`);
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onSelected(value) {
      // console.debug("month-picker onSelected", value);
      if (!value) {
        // has been cleared!
        this.$emit("clear");
      }
    },
    getValidationStateClass({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return "is-invalid";
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? ""
        : dirty || validated
        ? valid
          ? "is-valid"
          : "is-invalid"
        : "";
    },
  },
};
</script>
