<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-datepicker
              vid="data_da"
              name="Data da"
              label="Data da"
              v-model="form.request_input.data_da"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_a"
              name="Data a"
              label="Data a"
              v-model="form.request_input.data_a"
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :options="getInsurers()"
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      form: {
        report_id: this.reportId,
        format: "pdf",
        request_input: {
          compagnie: [],
          data_a: null,
          data_da: null,
          data_stampa: moment().format("YYYY-MM-DD"),
        },
      },
    };
  },

  mixins: [StorageGetterMixin],
  components: {
    BaseSelect,
    BaseDatepicker,
  },
  methods: {
    createReport() {
      this.form.request_input.compagnie = [this.form.request_input.compagnie];
      this.$emit("saveReport", this.form);
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
  },
  created() {
    /* */
    console.log("created INCACOMP2");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  // computed: {
  //   reportId() {
  //     return this.$store.state.auth.reports.find(
  //       (e) => e.code === this.reportCode
  //     ).id;
  //   },
  // },
  // mounted() {
  // debugger;
  // this.form.report_id = this.reportId;
  // },
};
</script>
