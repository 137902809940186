<script>
export default {
  props: {
    beFormReport: {
      type: Object,
      default() {
        return {};
      },
    },
    beRulesReport: {
      type: Object,
      default() {
        return {};
      },
    },
    reportId: Number,
    reportCode: String,
    allowedFormatOptions: Array,
  },
  methods: {
    convertArrayToObject(array) {
      let arrOfObjects = [];
      array.forEach((el, index) => {
        arrOfObjects.push({ value: index, text: el });
      });
      return arrOfObjects;
    },
    createReport() {
      this.$emit("saveReport", this.form);
    },
  },
  created() {
    // sto arrivando dalla rotellina, non da generated-reports:
    // il format è già stabilito, qui ne arriva 1 solo e semplicemente valorizzo il v-model
    if (!this.allowedFormatOptions && this.form.format)
      this.$set(this.form, "format", this.form.format);
    if (this.allowedFormatOptions && this.allowedFormatOptions.length === 1) {
      this.$set(this.form, "format", this.allowedFormatOptions[0].value);
    }
  },
};
</script>
