var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{attrs:{"mode":_vm.vmode,"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ dirty, validated, valid, errors }){return [_c('b-form-group',{class:{
      asterisk:
        Object.keys(_vm.rules).includes('required') ||
        (Object.keys(_vm.rules).includes('required_if') && !valid) ||
        (Object.keys(_vm.rules).includes('required_with') && !valid),
    },attrs:{"id":`input-group-${_vm.uid}`,"label":`${_vm.label}`,"label-for":`input-${_vm.uid}`}},[_c('monthpicker',{class:_vm.getValidationStateClass({ dirty, validated, valid, errors }),attrs:{"id":_vm.id,"name":`input-${_vm.uid}`,"monthLabels":[
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],"dateFormat":"MM/YYYY","selectedBackgroundColor":"#f9991e","clearOption":_vm.clearable,"aria-describedby":`input-${_vm.id}-live-feedback`},on:{"selected":_vm.onSelected},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}}),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"id":`input-${_vm.id}-live-feedback`}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }