import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [FormMixin],
  data() {
    return {
      reportCode: null,
      beRulesCreate: null,
      beFormCreate: null,
      allowed: [
        { text: "csv", value: "csv" },
        { text: "pdf", value: "pdf" },
      ],
    };
  },
  methods: {
    onReport(code) {
      this.reportCode = code;
      this.openReportModal(code);
    },
    openReportModal(code) {
      this.fetchEditForm("report", code)
        .then(() => {
          this.beFormCreate = this.beForm["report"];
          this.beRulesCreate = this.beRules["report"];
          this.$bvModal.show(code);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    saveReport(code, form) {
      switch (code) {
        case "TOTASOSP":
        case "REGIFOCA":
          this.onExport(this.exportFormat, code, form.request_input);
          this.$bvModal.hide(code);
          break;
        default:
          this.save(code, form);
      }
    },
    save(code, form) {
      const Repo = RepositoryFactory.get("report");
      Repo.report(code, form)
        .then(() => {
          this.resetForm();
          this.$showSnackbar({
            preset: "info",
            text: `Richiesta accettata: report in elaborazione`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.$bvModal.hide(code);
        });
    },
  },
};
