<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(additional_description)="{ item }">
            {{
              item?.payment_reference?.code
                ? `${item.payment_reference.code} - ${item.additional_description}`
                : item.additional_description
            }}
          </template>
          <template #cell(customer)="{ item }" v-if="items.length">
            {{
              item.registries.length
                ? item.registries[0].status_registry.value === 1
                  ? item.registries[0].attributables.CNAM
                  : item.registries[0].attributables.SURN +
                    " " +
                    item.registries[0].attributables.NAME
                : ""
            }}
          </template>
          <template #cell(gross_coass)="{ item }" v-if="items.length">
            <span
              v-if="!item.insurance_ancillaries[0]?.insurer_participants.length"
            >
              No
            </span>
            <span
              v-for="(insurer_participant, index) in item
                .insurance_ancillaries[0]?.insurer_participants"
              :key="index"
            >
              {{
                insurer_participant.code +
                "-" +
                insurer_participant.title +
                ": " +
                toLocaleCurrency(insurer_participant.pivot.gross)
              }}<br />
            </span>
          </template>
          <template #cell(insurer_description)="{ item }" v-if="items.length">
            <span>
              {{
                item.insurer ? `${item.insurer.code}-${item.insurer.title}` : ""
              }}
            </span>
          </template>
          <template #cell(document_code)="{ item }" v-if="items.length">
            <span v-for="(book_tag, index) in item.book_tags" :key="index">
              {{ `${book_tag.code}` }}
            </span>
          </template>
          <template #cell(take)="{ item }" v-if="items.length">
            {{ taking(item) }}
          </template>
          <template
            #cell(insurance_policy_number)="{ item }"
            v-if="items.length"
          >
            {{
              item.various_accountings && item.various_accountings.length
                ? item.various_accountings[0].pivot.insurance_policy_number
                : item.insurance_ancillaries &&
                  item.insurance_ancillaries.length
                ? item.insurance_ancillaries[0].insurance_policy
                  ? item.insurance_ancillaries[0].insurance_policy.number
                  : ""
                : ""
            }}
          </template>
          <template #cell(branch)="{ item }" v-if="items.length">
            {{
              item.insurance_ancillaries && item.insurance_ancillaries.length
                ? item.insurance_ancillaries[0].insurance_policy
                  ? item.insurance_ancillaries[0].insurance_policy
                      .insurance_risk
                    ? `${item.insurance_ancillaries[0].insurance_policy.insurance_risk.risk_branch.code}-${item.insurance_ancillaries[0].insurance_policy.insurance_risk.code}-
                    ${item.insurance_ancillaries[0].insurance_policy.insurance_risk.risk_branch.formatted_title}`
                    : ""
                  : ""
                : item.various_accountings && item.various_accountings.length
                ? item.various_accountings[0].pivot.insurance_risk
                  ? `${item.various_accountings[0].pivot.insurance_risk.risk_branch.code}-${item.various_accountings[0].pivot.insurance_risk.code}-
                  ${item.various_accountings[0].pivot.insurance_risk.risk_branch.formatted_title}
                  `
                  : ""
                : ""
            }}
          </template>
          <template #cell(product)="{ item }" v-if="items.length">
            {{
              item.insurance_ancillaries && item.insurance_ancillaries.length
                ? item.insurance_ancillaries[0].insurance_policy
                  ? item.insurance_ancillaries[0].insurance_policy
                      .insurance_risk.formatted_title
                  : ""
                : item.various_accountings && item.various_accountings.length
                ? item.various_accountings[0].pivot.insurance_risk
                  ? item.various_accountings[0].pivot.insurance_risk
                      .formatted_title
                  : ""
                : ""
            }}
          </template>
          <template #cell(saler_gross)="{ item }" v-if="items.length">
            {{
              toLocaleCurrency(item.saler_prov_purchase + item.saler_prov_take)
            }}
          </template>
          <template #cell(agency_gross)="{ item }" v-if="items.length">
            {{
              toLocaleCurrency(
                item.agency_prov_purchase + item.agency_prov_take
              )
            }}
          </template>
          <template #cell(note)="{ item }" v-if="items.length">
            {{
              item.tasks.length
                ? item.tasks.filter((task) => task.task_type.value === 2).length
                : 0
            }}
          </template>
          <template #cell(document)="{ item }" v-if="items.length">
            {{ item.documents.length ? item.documents.length : 0 }}
          </template>
          <template #cell(note_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note'
                )
              "
            >
              <!-- :title="
                  item.tasks.filter((task) => task.task_type.value === 2).length
                    ? 'Mostra Note'
                    : null
                " -->
              {{
                item.tasks.filter((task) => task.task_type.value === 2).length
              }}
            </div>
          </template>
          <template #cell(deferred)="{ item }" v-if="items.length">
            <div>
              <b-icon
                v-if="totalDeferred(item.entry_details)"
                icon="exclamation-circle-fill"
                variant="danger"
              ></b-icon>
            </div>
          </template>
          <template #cell(document_counter)="{ item }" v-if="items.length">
            <div
              :role="item.documents.length ? 'button' : null"
              class="info-box float-left"
              @click="
                item.documents.length
                  ? openDocumentDetailModal(item.documents)
                  : null
              "
              v-b-tooltip="
                toTooltipTask(item.documents, 'Documenti', [
                  { label: 'Titoli', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  { label: 'Ultimo aggiornamento', key: 'last_update' },
                ])
              "
            >
              <!-- :title="item.documents.length ? 'Mostra Documenti' : null" -->
              {{ item.documents.length }}
            </div>
          </template>
          <template #cell(cooperator)="{ item }" v-if="items.length">
            <span
              v-html="
                toInfoData(
                  item.brokers.find((e) => e.status_broker.value === 1),
                  'broker',
                  1
                )
              "
            ></span>
          </template>
          <template #cell(broker)="{ item }" v-if="items.length">
            <span
              v-html="
                toInfoData(
                  item.brokers.find((e) => e.status_broker.value === 0),
                  'broker',
                  0
                )
              "
            ></span>
          </template>
          <template #cell(entry_in)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(pos_entry(item, dbRowData.code)) }}
          </template>
          <template #cell(entry_out)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(Math.abs(neg_entry(item, dbRowData.code))) }}
          </template>
          <template #cell(pos_rebate)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(pos_entry(item, "AB")) }}
          </template>
          <template #cell(neg_rebate)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(neg_entry(item, "AB")) }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item)"
                class="mt-1 mr-1"
                title="Modifica"
                :disabled="hasBrokerStatement(row.item)"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                :disabled="
                  isImported(row.item) ||
                  isInsurerAccounting(row.item) ||
                  hasBrokerStatement(row.item)
                "
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>

          <template
            slot="thead-top"
            slot-scope="data"
            v-if="Object.keys(dbRowData).length && items.length"
          >
            <td v-for="(field, i) in data.fields" :key="i" class="totalRow">
              <b
                >{{ i == 0 && field.key === "book_date" ? "" : "" }}
                {{
                  i !== 0 && field.key === "additional_description"
                    ? checkBalanceLabelbyDate
                    : ""
                }}
                {{
                  i !== 0 && field.key === "entry_in"
                    ? `${checkBalancebyDate("in")}`
                    : null
                }}
                {{
                  i !== 0 && field.key === "entry_out"
                    ? `${checkBalancebyDate("out")}`
                    : null
                }}
              </b>
            </td>
          </template>
          <template
            slot="bottom-row"
            v-if="Object.keys(dbRowData).length && items.length"
          >
            <td colspan="4">
              <div class="row pagination--intable">
                <div class="show-text">
                  <p>Mostra</p>
                </div>
                <div class="group">
                  <b-form-group
                    label-for="per-page-select"
                    label-cols-sm="3"
                    label-cols-md=""
                    label-cols-lg="3"
                    label-size="sm"
                    md="auto"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      style="width: 68px"
                      size="sm"
                      @change="onPageChange(1)"
                      :disabled="!items.length"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="page">
                  <p>di {{ totalItems }} per pagina</p>
                </div>
                <div class="pagination" v-if="totalItems">
                  <b-pagination
                    v-model="currentPage"
                    limit="3"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    size="sm"
                    @change="onPageChange"
                  ></b-pagination>
                </div>
                <div
                  class="input_pagination"
                  v-if="totalItems && !noInputPaginator"
                >
                  <base-input-pagination
                    @pagination="onInputPagination"
                  ></base-input-pagination>
                </div>
              </div>
            </td>
          </template>
          <template slot="custom-foot" slot-scope="data">
            <template v-if="includeBottomRow && items.length">
              <td
                v-for="(field, i) in data.fields"
                :key="i"
                class="totalRow totalRow--upper-pagination"
              >
                <b
                  >{{ i === 0 ? "Totale: " : "" }}
                  {{
                    i !== 0 && field.key === "gross"
                      ? toLocaleCurrency(fieldSum(items, "gross"))
                      : null
                  }}
                  {{
                    i !== 0 && field.key === "agency_gross"
                      ? toLocaleCurrency(
                          fieldSum(items, "agency_prov_take") +
                            fieldSum(items, "agency_prov_purchase")
                        )
                      : null
                  }}
                  {{
                    i !== 0 && field.key === "saler_gross"
                      ? toLocaleCurrency(
                          fieldSum(items, "saler_prov_take") +
                            fieldSum(items, "saler_prov_purchase")
                        )
                      : null
                  }}
                </b>
              </td>
            </template>
            <template v-if="Object.keys(dbRowData).length && items.length">
              <td
                v-for="(field, i) in data.fields"
                :key="i"
                class="totalRow totalRow--upper-pagination"
              >
                <span
                  v-if="i !== 0 && field.key === 'additional_description'"
                  class="info"
                  v-html="
                    `Totale Movimenti<br><b>${checkFinalBalanceLabel}</b>`
                  "
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'entry_in'"
                  class="info"
                  v-html="
                    `${toLocaleCurrency(
                      dbRowData.entry_in
                    )}<br><b>${checkFinalBalance('in')}</b>`
                  "
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'entry_out'"
                  class="info"
                  v-html="
                    `${toLocaleCurrency(
                      Math.abs(dbRowData.entry_out)
                    )}<br><b>${checkFinalBalance('out')}</b>`
                  "
                >
                </span>
              </td>
            </template>
            <template v-if="Object.keys(extraFooter).length && items.length">
              <td v-for="(field, i) in data.fields" :key="i" class="totalRow">
                <span
                  v-if="i !== 0 && field.key === 'additional_description'"
                  class="info"
                  v-html="`Totale Movimenti<br>${checkFinalBalanceLabel}`"
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'pos_rebate'"
                  v-html="
                    `${
                      toLocaleCurrency(extraFooter.entry_in) +
                      '<br>' +
                      (extraFooter.entry_in + extraFooter.entry_out > 0
                        ? toLocaleCurrency(
                            extraFooter.entry_in + extraFooter.entry_out
                          )
                        : '')
                    }`
                  "
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'neg_rebate'"
                  class="info"
                  v-html="
                  `${
                 toLocaleCurrency(extraFooter.entry_out) +
                        '<br>' +
                        (extraFooter.entry_in + extraFooter.entry_out   &lt; 0
                          ? toLocaleCurrency(
                              extraFooter.entry_in + extraFooter.entry_out
                            )
                          : '')
                  }`
                "
                >
                </span>
              </td>
            </template>
          </template>
        </b-table>
      </div>
      <b-row
        v-if="!noPagination && !(Object.keys(dbRowData).length && items.length)"
      >
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>

              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="updatedData()"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/accounting/book-entries/details/General.vue";
import Appointments from "@/components/accounting/book-entries/details/Appointments";
import Todo from "@/components/accounting/book-entries/details/Todo";
import Notes from "@/components/accounting/book-entries/details/Notes";
import { toLocaleCurrency } from "@/utils/strings";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import Documents from "@/components/accounting/book-entries/details/Documents";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");

export default {
  name: "BookEntriesTable",
  extends: template,
  data() {
    return {
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Documents", text: "Doc" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    General,
    Appointments,
    Todo,
    Notes,
    ButtonGroupTab,
    BaseInputPagination,
    Documents,
  },
  methods: {
    moment,
    toLocaleCurrency,
    toLocaleDate,
    updatedData() {
      this.fetch();
    },
    pos_entry(item, code) {
      if (item.entry_details) {
        // siamo nei book_entry
        let sector_id = this.$store.state.auth.sectors.find(
          (e) => e.code === code
        ).id;
        let treasury_codes_of_sector = this.$store.state.auth.treasuries
          .filter((e) => e.sector_id === sector_id)
          .map((e) => e.code);

        const entry = item.entry_details?.filter(
          (e) =>
            treasury_codes_of_sector.includes(e.treasury?.code) && e.gross > 0
        );
        return entry?.reduce(function (previousValue, currentValue) {
          return previousValue + currentValue.gross;
        }, 0);
      } else {
        // siamo nei agency_book
        return item.gross > 0 ? item.gross : 0;
      }
    },
    neg_entry(item, code) {
      if (item.entry_details) {
        // siamo nei book_entry
        let sector_id = this.$store.state.auth.sectors.find(
          (e) => e.code === code
        ).id;
        let treasury_codes_of_sector = this.$store.state.auth.treasuries
          .filter((e) => e.sector_id === sector_id)
          .map((e) => e.code);

        const entry = item.entry_details?.filter(
          (e) =>
            treasury_codes_of_sector.includes(e.treasury?.code) && e.gross < 0
        );
        return entry?.reduce(function (previousValue, currentValue) {
          return previousValue + currentValue.gross;
        }, 0);
      } else {
        // siamo nei agency_book
        return item.gross < 0 ? item.gross : 0;
      }
    },
    isImported(item) {
      // try {
      //   return item?.insurance_ancillaries[0]?.insurance_policy?.insurer
      //     .importer_id
      //     ? true
      //     : false;
      // } catch (err) {
      //   return false;
      // }
      return item.imported_at ? true : false;
    },
    hasBrokerStatement(item) {
      return item.broker_statement_id ? true : false;
    },
    isInsurerAccounting(item) {
      return item?.insurer_accountings?.length > 0 ? true : false;
    },
    taking(item) {
      const take = item?.entry_details?.filter(
        (entry_detail) => !["EN", "PR"].includes(entry_detail?.treasury?.code)
      );
      return take.length ? "Si" : "No";
    },
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    openDocumentDetailModal(array) {
      this.$emit("open_document", array);
    },
    fieldSum(items, field) {
      items;
      return items
        .map((item) => item[field])
        .reduce((prev, next) => prev + next, 0);
    },
    totalDeferred(data) {
      return parseFloat(
        data
          .filter((el) => el?.treasury?.code == "SS")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
  },
  props: {
    currentBalance: {
      type: Object,
      default() {
        return {};
      },
    },
    startDate: {
      type: String,
      default() {
        return null;
      },
    },
    endDate: {
      type: String,
      default() {
        return null;
      },
    },
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
    extraFooter: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkStartDate() {
      return this.startDate
        ? !moment(this.startDate, "YYYY-MM-DD").isAfter(
            this.dbRowData?.initial_balance_date,
            "day"
          )
        : true;
    },
    checkBalanceLabelbyDate() {
      return toLocaleDate(this.dbRowData.initial_balance_date) ===
        toLocaleDate(this.startDate)
        ? `Saldo Iniziale al ${toLocaleDate(moment(this.startDate))}`
        : !this.startDate
        ? ""
        : `Saldo al ${toLocaleDate(
            moment(this.startDate).subtract(1, "d").format("YYYY-MM-DD")
          )}`;
    },
    checkFinalBalanceLabel() {
      return this.endDate
        ? `Saldo Finale al ${toLocaleDate(moment(this.endDate))}`
        : "Saldo Finale";
    },
    checkBalancebyDate() {
      return (type) => {
        const final_balance = this.checkStartDate
          ? this.dbRowData.initial_balance
          : this.dbRowData.initial_balance +
            this.currentBalance.entry_in +
            this.currentBalance.entry_out;
        return (type === "in" && final_balance > 0) ||
          (type === "out" && final_balance < 0)
          ? toLocaleCurrency(Math.abs(final_balance))
          : "";
      };
    },
    checkFinalBalance() {
      return (type) => {
        const final_balance =
          this.dbRowData.initial_balance +
          this.dbRowData.entry_in +
          this.dbRowData.entry_out +
          this.currentBalance.entry_in +
          this.currentBalance.entry_out;
        return (type === "in" && final_balance > 0) ||
          (type === "out" && final_balance < 0)
          ? toLocaleCurrency(Math.abs(final_balance))
          : "";
      };
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.b-table-bottom-row) {
  background-color: #e1ddda;
}
.totalRow {
  text-align: right;
}
.info {
  text-align: right;
}
.b-table-bottom-row {
  td {
    background-color: #fafafa;
    padding-block: 8px;
  }
  .row {
    margin: 0;
    justify-content: center;
    align-items: center;
    * {
      margin: 0;
    }
    .group {
      width: auto;
    }
    .page {
      + .pagination {
        padding-left: 10px;
        margin-left: 0;
        border-left: 1px solid #ccc;
        border-radius: 0;
      }
    }
    .pagination {
      width: auto;
    }
  }
}
tfoot,
.totalRow--upper-pagination {
  background-color: #e1ddda;
}
.table thead th,
thead,
th {
  background: 0 !important;
}
</style>
