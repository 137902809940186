<template>
  <svg
    focusable="false"
    role="img"
    class="loading-icon__svg loading-clock"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 384 384"
    width="35"
    height="35"
    xml:space="preserve"
  >
    <title>{{ getDictionary("loading") }}</title>
    <g>
      <circle
        class="loading-clock-frame"
        cx="192"
        cy="192"
        r="172"
        fill="none"
        stroke="currentColor"
        stroke-width="25"
      ></circle>
      <line
        class="loading-clock-hour-hand"
        id="loading-anim-clock-hour-hand"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="20"
        stroke-miterlimit="10"
        x1="192"
        y1="192"
        x2="192"
        y2="130"
      >
        <animateTransform
          type="rotate"
          fill="remove"
          restart="always"
          calcMode="linear"
          accumulate="none"
          additive="sum"
          repeatCount="indefinite"
          dur="12s"
          to="360 192 192"
          from="0 192 192"
          attributeName="transform"
          attributeType="xml"
        ></animateTransform>
      </line>
      <line
        class="loading-clock-minute-hand"
        id="loading-anim-clock-minute-hand"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="20"
        stroke-miterlimit="10"
        x1="192"
        y1="192"
        x2="192"
        y2="90"
      >
        <animateTransform
          type="rotate"
          fill="remove"
          restart="always"
          calcMode="linear"
          accumulate="none"
          additive="sum"
          repeatCount="indefinite"
          dur="3s"
          to="360 192 192"
          from="0 192 192"
          attributeName="transform"
          attributeType="xml"
        ></animateTransform>
      </line>
      <circle
        class="loading-clock-axis"
        fill="none"
        stroke="none"
        cx="192"
        cy="192"
        r="15"
      />
    </g>
  </svg>
</template>

<script>
import { getDictionary } from "@/utils/dictionary";
export default {
  methods: {
    getDictionary,
  },
};
</script>
