var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createReport)}}},[_c('b-card',[_c('b-card-text',[_c('base-radio',{attrs:{"name":_vm.beFormReport.format.label,"vid":"format","label":_vm.beFormReport.format.label,"options":_vm.allowedFormatOptions,"rules":{ required: true }},model:{value:(_vm.form.format),callback:function ($$v) {_vm.$set(_vm.form, "format", $$v)},expression:"form.format"}}),_vm._v(" "),_c('base-select',{attrs:{"vid":"relazioni","name":"relazioni","label":"Relazioni","options":_vm.beFormReport.relazioni.options},model:{value:(_vm.form.request_input.relazioni),callback:function ($$v) {_vm.$set(_vm.form.request_input, "relazioni", $$v)},expression:"form.request_input.relazioni"}}),_vm._v(" "),_c('base-radio',{attrs:{"name":"includi_gruppo","vid":"includi_gruppo","label":"Includi Gruppo","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.request_input.includi_gruppo),callback:function ($$v) {_vm.$set(_vm.form.request_input, "includi_gruppo", $$v)},expression:"form.request_input.includi_gruppo"}}),_vm._v(" "),_c('base-radio',{attrs:{"name":"includi_premi","vid":"includi_premi","label":"Includi Premi","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.includi_premi),callback:function ($$v) {_vm.$set(_vm.form, "includi_premi", $$v)},expression:"form.includi_premi"}}),_vm._v(" "),_c('base-radio',{attrs:{"name":"polizze_estinte","vid":"polizze_estinte","label":"Polizze Estinte","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.request_input.polizze_estinte),callback:function ($$v) {_vm.$set(_vm.form.request_input, "polizze_estinte", $$v)},expression:"form.request_input.polizze_estinte"}}),_vm._v(" "),_c('base-select',{attrs:{"vid":"compagnie","name":"compagnie","label":"Compagnie","taggable":true,"multiple":true,"options":_vm.beFormReport.compagnie.options},model:{value:(_vm.form.request_input.compagnie),callback:function ($$v) {_vm.$set(_vm.form.request_input, "compagnie", $$v)},expression:"form.request_input.compagnie"}})],1),_vm._v(" "),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","disabled":invalid,"variant":"outline-lisaweb","size":"sm"}},[_vm._v("\n          Salva\n        ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }