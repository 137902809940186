<template>
  <div>
    <b-tabs content-class="pt-1" lazy v-model="tabIndex">
      <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        @click="onTabClick(tab, index)"
        :title="tab.text"
        :active="active(index)"
        title-item-class="lisaweb"
      >
        <keep-alive>
          <component v-if="selected === tab.name" :is="selected"></component>
        </keep-alive>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import CompanyAccount from "@/components/accounting/bank-statement/company/index.vue";
import AgencyAccount from "@/components/accounting/bank-statement/agency/index.vue";
import RebateList from "@/components/accounting/bank-statement/rebate/index.vue";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin, CustomFiltersMixin],
  data() {
    return {
      path: "module.CONT",
      tabs: [
        { name: "CompanyAccount", text: "Conti di Compagnia" },
        { name: "AgencyAccount", text: "Conti di Agenzia" },
        { name: "RebateList", text: "Lista Abbuoni" },
      ],
      tabsGroup: "BankStatement",
      bcs: [
        {
          text: "Contabilità",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Contabilità",
          path: "",
          level: 1,
        },
        {
          text: "Estratti Conto",
          path: "",
          level: 2,
        },
        {
          text: "Conti di Compagnia",
          path: "module.CONT.ESCO",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "CompanyAccount",
      tabIndex: 0,
    };
  },
  components: {
    CompanyAccount,
    AgencyAccount,
    RebateList,
  },
};
</script>
