var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createReport)}}},[_c('b-card',[_c('b-card-text',[_c('base-select',{attrs:{"name":"includi_note","vid":"includi_note","label":"Includi le note se presenti","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ],"rules":{ required: true }},model:{value:(_vm.form.request_input.includi_note),callback:function ($$v) {_vm.$set(_vm.form.request_input, "includi_note", $$v)},expression:"form.request_input.includi_note"}}),_vm._v(" "),_c('base-select',{attrs:{"name":"provv_agenzia_produttore","vid":"provv_agenzia_produttore","label":"Mostra le provvigioni dell’agenzia/broker ","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ],"rules":{ required: true }},model:{value:(_vm.form.request_input.provv_agenzia_produttore),callback:function ($$v) {_vm.$set(_vm.form.request_input, "provv_agenzia_produttore", $$v)},expression:"form.request_input.provv_agenzia_produttore"}}),_vm._v(" "),_c('base-select',{attrs:{"name":"beFormReport.request_input.provv_collaboratori.label","vid":"provv_collaboratori","label":"Mostra le provvigioni dei collaboratori","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ],"rules":{ required: true }},model:{value:(_vm.form.request_input.provv_collaboratori),callback:function ($$v) {_vm.$set(_vm.form.request_input, "provv_collaboratori", $$v)},expression:"form.request_input.provv_collaboratori"}})],1),_vm._v(" "),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","disabled":invalid,"variant":"outline-lisaweb","size":"sm"}},[_vm._v("\n          Salva\n        ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }