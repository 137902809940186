<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :options="getInsurers()"
              :taggable="true"
              :multiple="true"
            />
            <base-select
              vid="produttori"
              name="produttori"
              label="Produttori"
              v-model="form.request_input.produttori"
              :options="getSalesmen()"
              :taggable="true"
              :multiple="true"
            />
            <base-radio
              vid="raggruppa_produttori"
              name="raggruppa_produttori"
              label="Attiva raggruppamento produttori"
              v-model="form.request_input.raggruppa_produttori"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
            />
            <base-radio
              vid="solo_totali_produttori"
              name="solo_totali_produttori"
              label="Attiva stampa solo totali per produttore"
              v-model="form.request_input.solo_totali_produttori"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      form: {
        format: null,
        request_input: {
          compagnie: [],
          produttori: [],
          raggruppa_produttori: [],
          solo_totali_produttori: [],
        },
      },
    };
  },
  components: {
    BaseRadio,
    BaseSelect,
  },
  methods: {
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
  },
};
</script>
