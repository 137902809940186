<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="display"
                type="text"
                label="Codice del comparto"
                v-model="filter.byAttribute.display"
                placeholder="Inserisci un codice"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Descrizione del comparto"
                v-model="filter.byAttribute.title"
                placeholder="Inserisci una descrizione"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <sectors
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :onlyActions="['infomodal']"
      :filterOn="{ byAttribute: { is_agency: 'Y', is_system: 'N' } }"
      :ref="tableRef"
      noPagination
    ></sectors>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Sectors from "@/components/tables/AgencySummarySectors.vue";
import { mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "sector",
      resource: "sectors",
      tableRef: "AgencySectorsSummaryTableRef",
      filterName: "filterAgencySectorsSummary",
      filter: this.initFilter(),
      args: null,
      fields: [
        {
          key: "display",
          label: this.getDictionary("display", "sector"),
          sortable: true,
          sortKey: "display",
        },
        {
          key: "title",
          label: this.getDictionary("title", "sector"),
          sortable: true,
          sortKey: "title",
        },
        {
          key: "initial_balance_date",
          label: this.getDictionary("initial_balance_date", "sector"),
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "initial_balance_date",
        },
        {
          key: "initial_balance",
          label: this.getDictionary("initial_balance", "sector"),
          formatter: (value) => toLocaleCurrency(value),
          sortable: true,
          sortKey: "initial_balance",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "entry_tot",
          label: this.getDictionary("entry_tot", "sector"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  components: {
    Sectors,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          display: null,
          title: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
