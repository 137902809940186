<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-datepicker
              vid="data_da"
              name="Data da"
              label="Data da"
              v-model="form.request_input.data_da"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_a"
              name="Data a"
              label="Data a"
              v-model="form.request_input.data_a"
              :rules="{ required: true }"
            />
            <base-radio
              name="format"
              vid="format"
              label="Formato"
              v-model="form.format"
              :options="[
                { text: 'pdf', value: 'pdf' },
                { text: 'csv', value: 'csv' },
              ]"
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseRadio from "@/components/form/BaseRadio";
import common from "@/components/reports/common.vue";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  extends: common,
  data() {
    return {
      form: {
        report_id: null,
        format: null,
        request_input: {
          data_a: null,
          data_da: null,
        },
      },
    };
  },
  mixins: [StorageGetterMixin],
  components: { BaseDatepicker, BaseRadio },
  created() {
    /* */
    console.log("created CONREGCOL");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  methods: {
    createReport() {
      this.$emit("saveReport", this.form);
    },
  },
  computed: {
    reportId() {
      return this.$store.state.auth.reports.find(
        (e) => e.code === this.reportCode
      ).id;
    },
  },
  mounted() {
    this.form.report_id = this.reportId;
  },
};
</script>
