<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-select
              name="includi_note"
              vid="includi_note"
              label="Includi le note se presenti"
              v-model="form.request_input.includi_note"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-select
              name="provv_agenzia_produttore"
              vid="provv_agenzia_produttore"
              label="Mostra le provvigioni dell’agenzia/broker "
              v-model="form.request_input.provv_agenzia_produttore"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-select
              name="beFormReport.request_input.provv_collaboratori.label"
              vid="provv_collaboratori"
              label="Mostra le provvigioni dei collaboratori"
              v-model="form.request_input.provv_collaboratori"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      form: {
        format: null,
        request_input: {
          includi_note: null,
          provv_agenzia_produttore: "Y",
          provv_collaboratori: "Y",
        },
      },
    };
  },
  components: {
    BaseSelect,
  },
};
</script>
