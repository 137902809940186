<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReportCustom)">
        <b-card>
          <b-card-text>
            <base-select
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-month-picker
              vid="mese_da"
              name="mese_da"
              label="Incassi dal mese"
              v-model="form.request_input.mese_da"
              :clearable="true"
              :rules="{ required: true }"
            />
            <base-month-picker
              vid="mese_a"
              name="mese_a"
              label="Incassi al mese"
              v-model="form.request_input.mese_a"
              :clearable="true"
              :rules="{ required: true }"
            />
            <base-input
              vid="anno"
              name="anno"
              label="Incassi anno"
              v-model="form.request_input.anno"
              :placeholder="beFormReport.request_input.anno.label"
              type="number"
              maxlength="4"
              :rules="{ required: true, between: { min: '1900', max: '2050' } }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :taggable="true"
              :multiple="true"
              @input="onInputInsurer"
              :disabled="insurersDisabled"
              :options="getInsurers()"
            />
            <base-select
              vid="tipi_rischio"
              name="tipi_rischio"
              label="Tipi di Rischio"
              v-model="form.request_input.tipi_rischio"
              @input="onInputRiskTypes"
              :options="
                getRisks().filter((risk) => ['P', 'L', 'D'].includes(risk.code))
              "
            />
            <base-select
              vid="rami_rischio"
              name="rami_rischio"
              label="Rami di Rischio"
              v-model="form.request_input.rami_rischio"
              :disabled="branchesDisabled"
              :taggable="true"
              :multiple="true"
              :options="getBranches()()"
            />
            <base-select
              vid="prodotti"
              name="prodotti"
              label="Prodotti"
              v-model="form.request_input.prodotti"
              :disabled="risksDisabled"
              :taggable="true"
              :multiple="true"
              :options="getProducts()()"
            />
            <base-select
              name="raggruppa_produttori"
              vid="raggruppa_produttori"
              label="Raggruppare i produttori"
              v-model="form.request_input.raggruppa_produttori"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-select
              name="stampa_polizze"
              vid="stampa_polizze"
              label="Stampare anche il dettaglio polizze"
              v-model="form.request_input.stampa_polizze"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-select
              name="tutte_polizze"
              vid="tutte_polizze"
              label="Considerare anche polizze stornate/annullate/disdettate nel periodo considerato"
              v-model="form.request_input.tutte_polizze"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-select
              vid="produttori"
              name="produttori"
              label="Produttori"
              v-model="form.request_input.produttori"
              :taggable="true"
              :multiple="true"
              :rules="{ required: true }"
              :options="getSalesmen()"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";
import moment from "moment";
moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      risksDisabled: false,
      insurersDisabled: false,
      branchesDisabled: false,
      form: {
        format: null,
        report_id: this.reportId,
        request_input: {
          anno: null,
          compagnie: [],
          mese_a: null,
          mese_da: null,
          prodotti: [],
          produttori: [],
          raggruppa_produttori: [],
          rami_rischio: [],
          stampa_polizze: null,
          tipi_rischio: [],
          tutte_polizze: null,
        },
      },
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseMonthPicker,
  },
  methods: {
    onInputRiskTypes(val) {
      if (val) {
        this.risksDisabled = true;
        this.insurersDisabled = true;
        this.branchesDisabled = true;
        this.form.request_input.compagnie = [];
        this.form.request_input.prodotti = [];
        this.form.request_input.rami_rischio = [];
      } else {
        this.risksDisabled = false;
        this.insurersDisabled = false;
        this.branchesDisabled = false;
      }
    },
    onInputInsurer(val) {
      if (val.length > 1) {
        this.risksDisabled = true;
        this.form.request_input.prodotti = [];
      }
    },
    createReportCustom() {
      this.form.request_input.mese_da = moment(
        this.form.request_input.mese_da
      ).format("YYYY-MM-DD");
      this.form.request_input.mese_a = moment(
        this.form.request_input.mese_a
      ).format("YYYY-MM-DD");
      this.$emit("saveReport", this.form);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getProducts: "products",
      getBranches: "branches",
      getRisks: "risks",
    }),
  },
};
</script>
