<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              name="format"
              vid="format"
              label="Formato"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-select
              vid="clienti"
              name="clienti"
              label="Cliente"
              v-model="cliente"
              :options="registryOptions"
              @search-change="getAjaxOptions"
              @input="onInputRegistry"
              :isLoading="isLoadingAjax"
              placeholder="Digita un nominativo"
              :rules="{ required: true }"
            />
            <base-radio
              name="includi_gruppo"
              vid="includi_gruppo"
              label="Includi Gruppo"
              v-if="cliente"
              v-model="form.request_input.includi_gruppo"
              @select="onSelectReportType"
              :options="[
                { value: 'Y', text: 'Attiva selezione per gruppo anagrafico' },
                {
                  value: 'N',
                  text: 'Attiva selezione in base alle relazioni anagrafiche',
                },
              ]"
            />
            <b-card
              header="Gruppi"
              class="mt-2 mb-2"
              v-if="form.request_input.includi_gruppo === 'Y'"
            >
              <b-card-text>
                <base-select
                  vid="gruppi"
                  name="gruppi"
                  label="Gruppi"
                  @input="onInputRegistryGroups"
                  v-model="form.request_input.gruppi"
                  :options="registryGroupOptions"
                />
                <base-select
                  vid="clienti"
                  name="clienti"
                  label="Cliente Associati al Gruppo"
                  v-model="form.request_input.clienti"
                  :multiple="true"
                  :taggable="true"
                  :options="registriesOptions"
                  placeholder="Scegli un cliente"
                />
              </b-card-text>
            </b-card>
            <b-card
              header="Relazioni"
              class="mt-2 mb-2"
              v-if="form.request_input.includi_gruppo === 'N'"
            >
              <b-card-text>
                <base-select
                  vid="relazioni"
                  name="relazioni"
                  label="Relazioni"
                  v-model="form.request_input.relazioni"
                  :multiple="true"
                  :taggable="true"
                  :options="relationOptions"
                />
              </b-card-text>
            </b-card>
            <base-datepicker
              vid="data_da"
              name="data_da"
              label="Periodo da"
              v-model="form.request_input.data_da"
            />
            <base-datepicker
              vid="data_a"
              name="data_a"
              label="Periodo a"
              v-model="form.request_input.data_a"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      isLoadingAjax: false,
      registryOptions: [],
      registryGroupOptions: [],
      relationOptions: [],
      registriesOptions: [],
      cliente: null,
      form: {
        format: null,
        request_input: {
          data_da: null,
          data_a: null,
          includi_gruppo: null,
          relazioni: [],
          gruppi: [],
          clienti: [],
        },
      },
    };
  },
  components: {
    BaseRadio,
    BaseDatepicker,
    BaseSelect,
  },
  methods: {
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        let querystring = `byAttribute[title]=${query}&byRegistryGroup&byRegistry&perPage=0`;
        const Repo = RepositoryFactory.get("registry");
        Repo.index(querystring)
          .then((response) => {
            const data = response.data.data;
            // Set registries options
            this.registryOptions = data.map((registry) => {
              return {
                value: registry.id,
                text: registry.title,
                item: registry,
              };
            });
            this.isLoadingAjax = false;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
            this.isLoadingAjax = false;
          });
      }
    },
    onInputRegistry(id) {
      if (id) {
        const found = this.registryOptions.find(
          (option) => option.item.id === id
        );
        if (found) {
          // Registry Groups
          if (found.item.registry_groups.length) {
            this.registryGroupOptions = found.item.registry_groups.map(
              (registry_group) => {
                return {
                  value: registry_group.id,
                  text: `${registry_group.code}-${registry_group.title}
                  ${
                    registry_group.pivot
                      ? registry_group.pivot.primary.value === "Y"
                        ? " - (Primario)"
                        : ""
                      : ""
                  }`,
                  item: registry_group,
                };
              }
            );
          } else {
            this.form.request_input.gruppi = [];
            this.form.request_input.clienti = [];
            this.registriesOptions = [];
            this.registryGroupOptions = [];
          }
          // Relative Types
          if (found.item.registries.length) {
            this.relationOptions = found.item.registries.map((registry) => {
              return {
                value: registry.id,
                text:
                  registry.status_registry.value === 1
                    ? `${registry.attributables.CREG}-${registry.pivot.relative_type.male}`
                    : registry.attributables.SEX === "M"
                    ? `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`
                    : `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`,
              };
            });
          } else {
            this.form.request_input.relazioni = [];
            this.relationOptions = [];
          }
        }
      } else {
        this.form.request_input.includi_gruppo = null;
        this.form.request_input.relazioni = [];
        this.form.request_input.gruppi = [];
        this.form.request_input.clienti = [];
        this.registriesOptions = [];
        this.relationOptions = [];
        this.registryGroupOptions = [];
      }
    },
    onInputRegistryGroups(id) {
      if (id) {
        const found = this.registryGroupOptions.find(
          (registryGroup) => registryGroup.value == id
        );
        if (found) {
          this.registriesOptions = found.item.registries
            .filter((registry) => registry.id !== this.cliente)
            .map((registry) => {
              return {
                value: registry.id,
                text: registry.title,
              };
            });
        }
      } else {
        this.registriesOptions = [];
        this.form.request_input.clienti = [];
      }
    },
    onSelectReportType(val) {
      console.log("Val: ", val);
      if (val === "Y") {
        this.form.request_input.relazioni = [];
      }
      if (val === "N") {
        this.form.request_input.gruppi = [];
        this.registriesOptions = [];
        this.form.request_input.clienti = [];
      }
    },
  },
};
</script>
