<template>
  <component :is="iconComponent" role="img" class="custom-svg-icon" />
</template>

<script>
const icons = {
  loading: require("@/assets/icons/LoadingIcon.vue").default,
};

export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },
  },

  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
};
</script>
