import { mapGetters, mapActions } from "vuex";

export default {
  beforeMount() {
    // seleziona il tab definito nel hash della route oppure quello salvato in vuex store (tabs)
    // https://stackoverflow.com/questions/52090371/how-to-navigate-to-specific-tab-in-bootstrap-vue-tabs-in-vue-routes
    let tab;
    let tIndex = this.tabs.findIndex(
      (tab) => tab.name === this.$route.hash.slice(1)
    ); // remove # from route.hash
    // shortcut hash takes precedence over vuex store tabs:
    if (tIndex !== -1) {
      this.tabIndex = tIndex;
      tab = this.tabs[this.tabIndex].name;
      this.selected = tab;
      // console.debug("tabIndex: ", this.tabIndex);
      // console.debug("shortcut hash: ", tab);
    } else {
      // use vuex store tabs
      tab = this.loadTab()(this.tabsGroup);
      tIndex = this.tabs.findIndex((elem) => elem.name === tab);
      if (tIndex !== -1) {
        this.tabIndex = tIndex;
        this.selected = tab;
        // console.debug("vuex store tab: ", tab);
      }
      // } else {
      //   this.tabIndex = 0;
      //   // this.selectd is already set as default
      // }
    }
    // let fromShortcut = this.$route.query.fromShortcut;
    // console.info(
    //   fromShortcut ? `:)) YES SHORTCUT :))` : " :(( NO SHORTCUT :(("
    // );
    let bcs = this.loadBCS();

    if (bcs.length) {
      // breadcrumbs are stored... I was coming from a shortcut
      // let maxLevel = 0;
      // if (!fromShortcut) {
      //   maxLevel = this.maxLevel;
      // }
      let maxLevel = this.maxLevel;
      bcs.forEach((element) => {
        if (maxLevel >= element.level) {
          this.notifyQueue({
            // text:
            //   !fromShortcut && element.tab
            //     ? this.tabs.find((tab) => {
            //         return tab.name === this.selected;
            //       }).text
            //     : element.text,
            text: element.tab
              ? this.tabs.find((tab) => {
                  return tab.name === this.selected;
                }).text
              : element.text,
            path: element.path,
            // tab: !fromShortcut && element.tab ? `#${this.selected}` : null,
            tab: element.tab ? `#${this.selected}` : null,
            level: element.level,
            disabled: element.disabled,
          });
        }
      });
    } else {
      // breadcrumbs are not stored... I was coming from sidebar or breadcrumbs bar
      this.bcs.forEach((element) => {
        // this.bcs is statically defined per component!!!
        // if(maxLevel >= element.level) {
        // console.debug(
        //   JSON.stringify({
        //     text: element.tab
        //       ? this.tabs.find((t) => {
        //           return t.name === this.selected;
        //         }).text
        //       : element.text,
        //     path: element.path,
        //     tab: element.tab ? `#${this.selected}` : null,
        //     level: element.level,
        //     disabled: element.disabled,
        //   })
        // );
        this.notifyQueue({
          text: element.tab
            ? this.tabs.find((t) => {
                return t.name === this.selected;
              }).text
            : element.text,
          path: element.path,
          tab: element.tab ? `#${this.selected}` : null,
          level: element.level,
          disabled: element.disabled,
        });
        // }
      });
    }
  },
  methods: {
    ...mapActions("tabs", { saveTab: "saveByName", removeTab: "removeByName" }),
    ...mapGetters("tabs", { loadTab: "loadByName" }),
    ...mapGetters("breadcrumbs", { loadBCS: "loadBreadcrumbs" }),
    ...mapActions("queue", ["notifyQueue"]),
    onTabClick(tab, index) {
      this.selected = tab.name;
      this.tabIndex = index;
      this.onSaveTab(this.tabsGroup, this.selected);
      this.notifyQueue({
        text: tab.text,
        path: this.path, // NOTE: set this.path!!!
        tab: tab.name,
        level: this.maxLevel,
      });
    },
    onSaveTab(name, selected) {
      this.saveTab({ name, selected });
    },
  },
  computed: {
    active() {
      return (index) => {
        index === this.tabIndex;
      };
    },
  },
};
